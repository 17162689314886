// ** Third Party Components
import styled, { css, keyframes } from "styled-components";

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const StyledSvgIconContainer = styled.div`
	display: flex;
	color: ${({ theme: { isDarkMode } }) =>
		isDarkMode
			? "var(--dz-ref-color-gray800)"
			: "var(--dz-ref-color-gray700)"};

	${({ customMargin }) =>
		customMargin &&
		css`
			margin: ${customMargin};
		`}

	svg {
		display: inline-block;
		vertical-align: middle;
		fill: ${({ theme: { isDarkMode } }) =>
			isDarkMode
				? "var(--dz-ref-color-gray800)"
				: "var(--dz-ref-color-gray700)"};
		animation: ${rotate} 2s linear infinite;
		animation-delay: 0.1s; /* Opóźnienie 0.5 sekundy */

		${({ centerOnlyIcon }) =>
			centerOnlyIcon &&
			css`
				margin-left: 50%;
			`}
	}
`;

const Loader = ({ size = 30, customMargin, centerOnlyIcon }) => {
	// Wszystkie SVG w tym komponencie są od nas z plików i nie miały zapisu o licencji.
	return (
		<StyledSvgIconContainer
			customMargin={customMargin}
			centerOnlyIcon={centerOnlyIcon}
		>
			<svg
				height={`${size}px`}
				aria-hidden="true"
				focusable="false"
				data-prefix="fas"
				data-icon="spinner"
				className="svg-inline--fa fa-spinner fa-w-16"
				role="img"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 512 512"
			>
				<path
					fill="currentColor"
					d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
				></path>
			</svg>
		</StyledSvgIconContainer>
	);
};

export default Loader;
