// ** React Imports
import { useContext, useMemo } from "react";

// ** Third Party Components
import HoverVideoPlayer from "components/video/HoverVideoPlayer";

// ** Custom Components
import MediaImage from "./MediaImage";

// ** Custom Hooks
import useMobileDetect from "hooks/useMobileDetectHook";
import { useCalculateMediaItemPadding } from "hooks/useCalculateMediaItemPadding";

// ** Contexts
import AuthContext from "contexts/authContext";

// ** Styled Components
import {
	StyledMediaWrapper,
	StyledMediaContainer,
	StyledLiveContainer,
} from "../styles";

// #####################################################

const MediaVideo = ({
	sourceFull,
	sourcePreview,
	thumbnail,
	isCover: _isCover,
	isSquared: _isSquared,
	isMasonry,
	isWebcam,
	width,
	height,
	maxHeight,
	iconSize,
	withRatio_4_3 = false,
	withoutLive = false,
}) => {
	const isAuth = useContext(AuthContext);

	const isSquared = useMemo(() => {
		if (_isSquared == null) {
			return false;
		}
		return _isSquared;
	}, [_isSquared]);

	const isCover = useMemo(() => {
		if (_isCover !== undefined && _isCover !== null) {
			return _isCover;
		}

		if (width == null || height == null) {
			return true;
		}

		return false;
	}, [_isCover, width, height]);

	const padding = useCalculateMediaItemPadding({
		width,
		height,
		isSquared,
		withRatio_4_3,
	});

	const maxWidth = maxHeight ? `${maxHeight / (height / width)}px` : null;

	// ** Hook useMobileDetect
	const mobileDetect = useMobileDetect();
	// ** Sprawdzamy czy użytkownik korzysta z urzadznie z systemem iOS
	const isIos = mobileDetect.isIos();

	// #####################################################
	return (
		<StyledMediaContainer
			className="noselect"
			isCover={isCover}
			maxWidth={maxWidth}
			key={thumbnail}
			onClick={() => !isAuth && window.scrollTo(0, 0)}
		>
			<StyledMediaWrapper
				padding={Number.isNaN(padding) ? undefined : padding}
				isCover={isCover}
			>
				<HoverVideoPlayer
					videoSrc={
						<>
							{sourcePreview && (
								<source src={sourcePreview} type="video/webm" />
							)}
							{sourceFull && (
								<source src={sourceFull} type="video/mp4" />
							)}
						</>
					}
					playbackStartDelay={100}
					playbackRangeEnd={isIos ? 5 : undefined}
					unloadVideoOnPaused={true}
					preload="none"
					loop
					sizingMode="overlay"
					videoStyle={{
						filter: `${!isAuth ? "blur(7px)" : "none"}`,
					}}
					style={{
						overflow: "hidden",
						width: "100%",
						maxHeight: "100%",
						maxWidth: "100%",
						minWidth: "100%",
						position: "absolute",
						zIndex: 0,
						left: 0,
						bottom: 0,
						top: 0,
						right: 0,
					}}
					pausedOverlayWrapperStyle={{
						display: "block",
						width: "100%",
						height: "100%",
					}}
					pausedOverlay={
						<MediaImage
							thumbnail={thumbnail}
							alt=""
							width={width}
							height={height}
							isMasonry={isMasonry}
							iconSize={iconSize}
							withPlayIcon={!isWebcam}
							isCover={isCover}
							withRatio_4_3={withRatio_4_3}
						/>
					}
				/>
				{isWebcam && !withoutLive && (
					<StyledLiveContainer>LIVE</StyledLiveContainer>
				)}
			</StyledMediaWrapper>
		</StyledMediaContainer>
	);
};

export default MediaVideo;

// #####################################################
