// ** React Imports
import { useContext, useMemo, useState } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Custom Components
import StyledIcon from "components/global/StyledIcon/StyledIcon";
import Loader from "components/layout/Loader";
import { StyledLoaderWrapper } from "components/layout/LoaderWrapper.styled";

// ** Custom Hooks
import { useCalculateMediaItemPadding } from "hooks/useCalculateMediaItemPadding";

// ** Contexts
import AuthContext from "contexts/authContext";

// ** Styled Components
import {
	StyledMediaWrapper,
	StyledMediaContainer,
	StyledImage,
} from "../styles";
import { StyledOverlay } from "styles/components/Overlay.styled";

// ** Icons
import { ReactComponent as PlaySolidIcon } from "resources/icons/solid/play-solid.svg";

// #####################################################

const MediaImage = ({
	withPlayIcon,
	withSpinner,
	withoutLazyLoading = false,
	thumbnail,
	isBlurred: _isBlurred,
	isCover: _isCover,
	isSquared: _isSquared,
	isFilling = true,
	width,
	height,
	maxHeight,
	iconSize,
	alt,
	zoom,
	children,
	withRatio_4_3 = false,
}) => {
	const isAuth = useContext(AuthContext);
	const isClient = useSelector((state) => state.global.isClient);

	const [isLoaded, setIsLoaded] = useState(!isClient);

	const isBlurred = useMemo(() => {
		return !isAuth;
	}, [_isBlurred]);

	const isSquared = useMemo(() => {
		if (_isSquared == null) {
			return false;
		}
		return _isSquared;
	}, [_isSquared]);

	const isCover = useMemo(() => {
		if (_isCover !== undefined && _isCover !== null) {
			return _isCover;
		}

		if (width == null || height == null) {
			return true;
		}

		return false;
	}, [_isCover, width, height]);

	const padding = useCalculateMediaItemPadding({
		width,
		height,
		isSquared,
		withRatio_4_3,
	});

	const maxWidth = maxHeight ? `${maxHeight / (height / width)}px` : null;

	const handleImageLoad = () => {
		setIsLoaded(true);
	};

	const loadingMemonized = useMemo(() => {
		return withoutLazyLoading ? undefined : "lazy";
	}, [withoutLazyLoading]);

	// #####################################################

	return (
		<StyledMediaContainer maxWidth={maxWidth} isCover={isCover}>
			<StyledMediaWrapper
				padding={Number.isNaN(padding) ? undefined : padding}
				isCover={isCover}
				zoom={zoom}
				isFilling={isFilling}
			>
				<StyledImage
					src={thumbnail}
					alt={alt}
					isBlurred={isBlurred}
					isFilling={isFilling}
					isCover={isCover}
					isLoaded={isLoaded}
					onLoad={handleImageLoad}
					loading={loadingMemonized}
					height={height}
					width={width}
				/>
				{!isLoaded && withSpinner && (
					<StyledLoaderWrapper>
						<Loader />
					</StyledLoaderWrapper>
				)}
				<>
					{withPlayIcon && (
						<StyledIcon size={iconSize} full isMasonry noBackground>
							<PlaySolidIcon />
						</StyledIcon>
					)}
					<StyledOverlay />
				</>
			</StyledMediaWrapper>
			{children}
		</StyledMediaContainer>
	);
};

export default MediaImage;

// #####################################################
